import React, { useEffect, useState } from "react"
import baseUrl from "../../components/baseUrl"
import Axios from "axios"
import Confirm from "../../components/confirm"
import Layout from "../../components/layout"
import { TrashIcon } from "@heroicons/react/solid"
import { Link, navigate } from "gatsby"

function EditLog(props) {
  const [showConfirmDeleteModal, setDeleteShowModal] = useState(false)
  const [info, setInfo] = useState({
    _id: "",
    name: "",
    username: "",
    date: "",
    report: "",
  })
  const [showModal, setShowModal] = useState(false)

  const handleEdit = () => {
    Axios.post(`${baseUrl}/editlogs`, {
      rep_id: props.params.id,
      new_report: report,
    }).then(alert("Changes saved successfully")).catch(err => alert(err))
    navigate("/logbook")
    setShowModal(false)
  }

  const handleDelete = () => {
    console.log(props.params.id)
    Axios.patch(`${baseUrl}/dailylogs`, { data: props.params.id }).then(alert("Log deleted successfully"))
    .catch(err => alert.log(err))
    setDeleteShowModal(false)
    navigate("/logbook")
  }
  useEffect(() => {
    Axios.get(`${baseUrl}/dailylogs?id=${props.params.id}`).then(jsonRes => {
      setInfo(jsonRes.data)
    })
  }, [])
  const [report, setReport] = useState(info.report)
  return (
    <>
      <Layout route="/logbook">
        <div className="logPage">
          <div className="flex ">
            {" "}
            <h2 className="p-6">EDIT REPORT</h2>
            <button
              type="button"
              className="ml-auto p-6 outline-none focus:outline-none"
              onClick={e => {
                e.preventDefault()
                setDeleteShowModal(true)
              }}
            >
              <TrashIcon className="h-9 w-9 text-red-600" aria-hidden="true" />
            </button>
          </div>
          <div className="formRow p-6 pb-0 md:p-6 md:pb-6 ">
            <form class="w-full">
              <div class="flex flex-wrap -mx-3  mb-6">
                <div class="w-full  px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs  mb-2">
                    EDIT YOUR REPORT HERE
                  </label>

                  <div
                    contentEditable="true"
                    onInput={e => setReport(e.currentTarget.textContent)}
                    class="appearance-none block w-full h-[50vh] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    {info.report}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="flex items-center justify-start p-6 md:p-6  border-t border-solid border-blueGray-200 rounded-b">
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600 font-semibold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                if (report == "") {
                  alert("Cannot submit empty report!")
                } else setShowModal(true)
              }}
            >
              SAVE CHANGES
            </button>

            <Link to="/logbook">
              <button
                className="bg-red-600 text-white active:bg-red-600 font-semibold ml-6 uppercase  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
                BACK
              </button>
            </Link>
          </div>
        </div>
        {showModal ? (
          <Confirm
            title="Save changes?"
            task={handleEdit}
            setShowModal={setShowModal}
          />
        ) : null}
      </Layout>

      {/* Deletion confirmation modal */}
      {showConfirmDeleteModal ? (
        <Confirm
          title="Delete report?"
          task={handleDelete}
          setShowModal={setDeleteShowModal}
        />
      ) : null}
    </>
  )
}

export default EditLog
